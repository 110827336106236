












































































































































































































































































































































































































































































































































































































































































































































































.payment-page {
  max-width: 1024px;
  margin: 0 auto;
  .currency-input-clear {
    .el-icon-close::before {
      color: var(--schema-color);
    }
  }
}
