





























































































.radio-button-group {
  height: 45px;
  background: #F6F7F9;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    width: 100%;
    height: 100%;
    margin: 0;
    appearance: none;
    outline: none;
    cursor: pointer;
    color: #2D2E2F;
    font-size: 14px;
    font-family: "Raleway Bold", serif;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:checked {
      color: #FFFFFF;
      background-color: var(--active-bg);
      box-shadow: var(--active-shadow);
      border-radius: 24px;
    }

    &:before {
      content: attr(label);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
